<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary">eWork</h2>
      </b-link>

      <!-- Login v1 -->
      <b-card class="mb-0 pb-3">
        <b-card-text class="mb-2 text-center">
          Renseignez votre mot de passe à votre compte pour plus de sécurité.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginPasswordForm">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="data.password"
                    type="password"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Confirmer votre mot de passe</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="confirm_password"
                    :type="'password'"
                    class="form-control-merge"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="loading"
              @click="setPassword"
            >
            <b-spinner small v-show="loading" ></b-spinner>
              Valider
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
  
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      data: {
        password: "",
        email: JSON.parse(localStorage.getItem("customerInfo")).email,
      },
      status: "",
      confirm_password: "",
      status: "",
      // validation rules
      required,
    };
  },
  computed: {
    ...mapState('customer', ['customerInfo']),

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

   watch: {
    confirm_password() {
      return this.data.password != this.confirm_password ? this.status = "error" : this.status = "success";
    },
  },

  methods: {

     ...mapActions('customer', ['login', 'createCustomerPassword']),

    setPassword: async function () {
      this.loading = true;
      const isValid = await this.$refs.loginPasswordForm.validate();
      if (!isValid || this.status == 'error') return;
     
      this.createCustomerPassword(this.$formData(this.data)).then((res) => {
        this.loading = false;
        this.is_password = 2;
        this.$router.replace({ name: 'customer-space' }).then(() => {
          window.location.reload();
        });
      })
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
